import { React, useEffect, useState } from "react";
import style from "./auth.module.scss";
import { IMG_URL } from "../../../configuration/apiconfig";
import AppInfoservice from "../../../service/common/Appinfo";
import { useSelector } from "react-redux";
//import imge from '../../assets/images/logos/logo123456.png';
//import darkimage from '../../assets/images/logos/darklogo.png'
import imge from "../../../assets/images/logos/logo123456.png";
import darkimage from "../../../assets/images/logos/darklogo.png";

const Brand = () => {
  const [logo, setLogo] = useState();
  const service = new AppInfoservice();
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(()=>{
    service.Appinfo().then((res)=>{
        if(res.status){
          setLogo(res.data.company_info.logo)
        }else{
            
        }
    })  
},[])                                                                           

  return (                                                                                                                                
    <div className={`d-block mt-2 ${style.loginNavBrand}`}>
    <img src={IMG_URL+"logos/"+logo } alt="" className={style.loginNavBrandImg}/>
</div>
  );
};

export default Brand;
