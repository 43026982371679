import React from "react";
import { Container } from "react-bootstrap";
import style from "./dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import EarningsTab from "./EarningsTab";
import TeamPerformance from "./TeamPerformance";
// import TeamPerformanceNew from './TeamPerformanceNew';
import Members from "./Members";
import Charts from "./charts";
import Ranks from "./Ranks";

import DashboardModel from "./DashboardModal";
import { useTranslation } from "react-i18next";

import AddForm from "./AddForm";
import VerificationForm from "./VerificationForm";

import { Dashboard } from "@mui/icons-material";
function MainContent(props) {
  const { t } = useTranslation();
console.log("properties",props)
  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div className={style.dashboard_main_cnt}>
                <Profiles
                  profile={props.profile}
                  lodervalue={props.lodervalue}
                //  upgradelastdate={props.upgradelastdate}
                />
                <Tile
                  pvData={props.extra_data}
                  SponserData={props.sponser_details}
                  Tiles={props.tiles}
                  filterChange={props.filterChange}
                  lodervalue={props.lodervalue}
                />
                <div className={style.dash_member_join_rank_sec}>
                  <>
                    <Members
                      memberList={props.new_members}
                      lodervalue={props.lodervalue}
                    />

                     <Charts
                      data={props.joining_graph_data_new}
                      filterChange={props.chartfilterChange}
                      lodervalue={props.lodervalue}
                    /> 

                    {props.rank && <Ranks rank={props.rank} />}
                  </>
                </div>
                <div className={style.dashbord_earning_expence}>
                  <TeamPerformance
                    topEarners={
                      props.team_perfomance && props.team_perfomance.top_earners
                    }
                    topRecruters={
                      props.team_perfomance &&
                      props.team_perfomance.top_recruters
                    }
                    packageoverviews={
                      props.team_perfomance &&
                      props.team_perfomance.package_overview
                    }
                    rankOverviews={
                      props.team_perfomance &&
                      props.team_perfomance.rank_overview
                    }
                    lodervalue={props.lodervalue}
                    data={props}
                  />

                  <EarningsTab
                    lodervalue={props.lodervalue}
                    income={
                      props.earnings_nd_expenses &&
                      props.earnings_nd_expenses.incomes
                    }
                    expenses={
                      props.earnings_nd_expenses &&
                      props.earnings_nd_expenses.expenses
                    }
                    payoutstatus={
                      props.earnings_nd_expenses &&
                      props.earnings_nd_expenses.payout_statuses
                    }
                  />
                </div>
          {/* { props.upgradelastdate &&
                // <div className={style.upgarade_alert_box}>
                //   <div className={style.upgarade_alert_box_cnt}> 
                //   {/* <div className={style.upgarade_alert_box_close}>X</div> */}
                {/* //     <h4>Your plan expiring soon</h4>
                //     <span>{t("Dashboard.upgrade_last_date")}</span>
                //     <strong>{props.upgradelastdate}</strong>
                //   </div> */}
          
                {/* // </div> */}
          {/* } */} 
              </div>
            </div>
          </section>
        </div>
      </div>
      <DashboardModel show={props.modal.show} title={props.modal.title}>
        {!props.continue && <AddForm {...props}></AddForm>}
        {props.continue && <VerificationForm {...props}></VerificationForm>}
      </DashboardModel>
    </Container>
  );
}

export default MainContent;
