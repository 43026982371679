import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  Header,
  Banner,
  Plans,
  About,
  Contact,
  Footer,
  Scrollbutton,
} from "../../components/replica";
import { useParams } from "react-router-dom";
import ReplicaSiteService from "../../../service/replica/replica";
import AppInfoservice from "../../../service/common/Appinfo";
import {useSelector}  from 'react-redux';



function Home() {
  const { user_name, admin } = useParams();
  // const params = useParams();
  console.log("----------",user_name, admin)

  const service = new ReplicaSiteService();
  const app = new AppInfoservice();
  const Lang      = useSelector(state => state.repLang);//current language

  const [state, setState] = useState({
    header_data: [],
    policy_data: [],
    banner_data: [],
    loding: true,
    logo: "",
  });

 

  useEffect(() => {
    // service.getApi(admin).then((res) => {
    //   if (res.status) {
    //     localStorage.setItem('apiKey', res.data.key);
        service.getHeader(user_name, admin).then((res) => {
          if (res.status) {
            setState((prevState) => ({
              ...prevState,
              header_data: res.data,
              loding: false,
            }));
          } else {
            alert("Invalid Url");
          }
          app.Appinfo(user_name).then((res) => {
            console.log(res);
            if (res.status) {
              setState((prevState) => ({
                ...prevState,
                logo: res.data.company_info.login_logo,
              }));
            }
          });
        });
        getBanner();
        getPolicy();
    //   } else {
    //     alert("Invalid Admin user name");
    //   }
    // });
  }, [Lang]);

  const getBanner = () => {
    service.getBanner(user_name).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          banner_data: res.data,
        }));
      } else {
        alert("Invalid Url");
      }
    });
  };
  const getPolicy = () => {
    service.getPolicy(user_name).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          policy_data: res.data,
        }));
      } else {
      }
    });
  };

  return (
    <div className="h-100">
      {!state.loding && (
        <>
          {state.header_data && (
            <Header
              data={state.header_data}
              user_name={user_name}
              logo={state.logo}
              showmenu={true}
            />
          )}

          {state.banner_data && state.policy_data && (
            <Banner data={state} header_data={state.header_data} />
          )}
          {state.policy_data && <Plans data={state} />}
          {state.policy_data && <About data={state} />}
          {state.policy_data && <Contact data={state} user_name={user_name} />}
          <Scrollbutton />
          {state.policy_data && <Footer data={state} user_name={user_name} />}
        </>
      )}
    </div>
  );
}

export default withTranslation()(withRouter(Home));
