
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo2.infinitemlmdemo.com/ronald_joselin/backoffice/api/";
// const IMG_URL = "http://demo2.infinitemlmdemo.com/ronald_joselin/uploads/images/";
// const BASE = "http://demo2.infinitemlmdemo.com/ronald_joselin";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = '43f1d9e9-b989-404a-b382-ca2166052735'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY

// }

import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://backoffice.inspireworldwide.com/admin/backoffice/api/";
const IMG_URL = "https://backoffice.inspireworldwide.com/admin/uploads/images/";
const BASE = "https://backoffice.inspireworldwide.com/admin/backoffice";
// const API_KEY ='2aa4e7c5-f13e-4344-a7ea-f5c4efe04f8b';
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = '43f1d9e9-b989-404a-b382-ca2166052735';
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}
